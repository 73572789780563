import cx from 'classnames'

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  type: React.ComponentPropsWithoutRef<'button'>['type']
}

export function Button({
  children,
  className,
  disabled,
  ...rest
}: ButtonProps) {
  return (
    <button
      className={cx(
        'inline-block rounded-md bg-neutral-500 px-4 py-[7px] text-center font-lato text-sm font-medium text-white shadow-button transition-colors',
        {
          'hocus:bg-neutral-600 hocus:shadow-button-hocus': !disabled,
          'opacity-50': disabled,
        },
        className
      )}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  )
}
